<template>

  <div class="container
              page-container
              is-flex
              is-justify-content-center
              is-align-items-center">

    <div class="p-4">

      <div class="has-text-centered">
        <p class="is-size-4 is-size-5-mobile has-text-grey-dark mb-6">
          Bienvenue sur le lecteur de QR Code <br>
          <span class="has-text-weight-semibold">Cookie.menu</span> !
        </p>
        <p class="has-text-grey mb-1">
          Pour consulter le menu, scannez le QR code en cliquant sur le bouton ci-dessous.
        </p>
        <p class="has-text-grey-light">
          (Please press the button below to scan the QR code)
        </p>
      </div>

      <div class="has-text-centered p-6">
        <svg style="width:120px;height:120px" viewBox="0 0 24 24">
          <!-- eslint-disable-next-line max-len -->
          <path fill="#62d4b3" d="M4,4H10V10H4V4M20,4V10H14V4H20M14,15H16V13H14V11H16V13H18V11H20V13H18V15H20V18H18V20H16V18H13V20H11V16H14V15M16,15V18H18V15H16M4,20V14H10V20H4M6,6V8H8V6H6M16,6V8H18V6H16M6,16V18H8V16H6M4,11H6V13H4V11M9,11H13V15H11V13H9V11M11,6H13V10H11V6M2,2V6H0V2A2,2 0 0,1 2,0H6V2H2M22,0A2,2 0 0,1 24,2V6H22V2H18V0H22M2,18V22H6V24H2A2,2 0 0,1 0,22V18H2M22,22V18H24V22A2,2 0 0,1 22,24H18V22H22Z" />
        </svg>
      </div>

      <div class="scan_button">
        <b-button size="is-large"
                  expanded
                  class="is-uppercase"
                  type="is-dark"
                  :loading="loading"
                  @click="isScannerDisplayed = true">
          Scanner
        </b-button>
      </div>

    </div>

    <b-modal v-model="isScannerDisplayed"
             has-modal-card
             trap-focus
             :can-cancel="['x']"
             :destroy-on-hide="true"
             aria-role="dialog"
             aria-label="qr code reader dialog"
             aria-modal
             :full-screen="true">
      <template #default="props">
        <app-qr-code-reader @close="props.close();" />
      </template>
    </b-modal>

  </div>

</template>

<script>
import QRCodeReader from '@/components/client/QRCodeReader.vue';

export default {
  components: {
    'app-qr-code-reader': QRCodeReader,
  },
  data: () => ({
    isScannerDisplayed: false,
    loading: false,
  }),
};
</script>

<style lang="scss" scoped>
  .page-container {
    min-height: calc(100vh - 162px);
  }
</style>