<template>

  <div class="modal-card">

    <button class="delete is-large close-btn"
            type="button"
            @click="$emit('close')" />

    <qrcode-stream @decode="onDecode" @init="onInit" />

    <div class="target">
      <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" fill-opacity="0.1" width="100%" height="100%" viewBox="0 0 24 24"><path d="M11 8h-1v-2h1v1h2v1h-1v1h-1v-1zm2 12v-1h-1v1h1zm-1-15v-1h-2v1h1v1h1v-1zm8-1v6h-1v-1h-4v-5h5zm-1 4v-3h-3v3h3zm-14 2h-1v1h2v-1h-1zm0 3h1v1h1v-3h-1v1h-2v2h1v-1zm5 1v2h1v-2h-1zm4-10h-1v3h1v-3zm0 5v-1h-1v1h1zm3-2h1v-1h-1v1zm-10-1h-1v1h1v-1zm2-2v5h-5v-5h5zm-1 1h-3v3h3v-3zm9 5v1h-1v-1h-2v1h-1v-1h-3v-1h-1v1h-1v1h1v2h1v-1h1v2h1v-2h3v1h-2v1h2v1h1v-3h1v1h1v2h1v-1h1v-1h-1v-1h-1v-1h1v-1h-2zm-11 8h1v-1h-1v1zm-2-3h5v5h-5v-5zm1 4h3v-3h-3v3zm12-3v-1h-1v1h1zm0 1h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v2h-1v1h-1v3h1v-1h1v-1h2v2h1v-1h1v1h2v-1h1v-1h-2v-1zm-9-3h1v-1h-1v1zm10 2v1h1v1h1v-3h-1v1h-1zm2 4v-1h-1v1h1zm0-8v-1h-1v1h1zm-2-10h4v4h2v-6h-6v2zm-16 4v-4h4v-2h-6v6h2zm4 16h-4v-4h-2v6h6v-2zm16-4v4h-4v2h6v-6h-2z"/></svg>
    </div>

  </div>

</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  components: {
    QrcodeStream,
  },
  data: () => ({
    error: null,
  }),
  created() {

  },
  methods: {
    async onInit(promise) {
      const loading = this.$buefy.loading.open({
        container: null,
      });

      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
        loading.close();
      }
    },
    onDecode(decodedString) {
      if (decodedString) {
        window.location.href = decodedString;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-card-body {
    position: relative;
  }
  .target {
    position: absolute;
    z-index: 999;
    width: 300px;
    height: 300px;
    left: calc(100% / 2 - 150px);
    top: calc(100vh / 2 - 150px);
  }
  .close-btn {
    position: absolute;
    z-index: 1000;
    top: 20px;
    right: 20px;
  }
</style>